// Experience.js
import React from "react"
import styled from "styled-components"

const ExperienceContainer = styled.div`
  display: flex;
  width: 100%;
  padding-left: 50px;
  padding-right: 40px;
  @media (max-width: 768px) {
    padding: 0;
    flex-direction: column-reverse;
  }
`

const Timeline = styled.div`
  width: 15%;
  color: #0a192f;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const DateRange = styled.p`
  text-align: left;
  font-weight: bold;
  color: ${props => props.theme.primary};
  line-height: 1.5rem;
  font-size: 1.2rem;
`

const DetailsWrapper = styled.div`
  width: 85%;
  margin-left: 10px;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    text-align: justify;
  }
`

const Description = styled.p`
  color: white;
  line-height: 1.7rem;
  font-size: 1.2rem;
  @media (max-width: 768px) {
    text-align: justify;
  }
`

const Experience = ({ startDate, endDate, description }) => {
  const splittedStartDate = startDate.split(".")

  return (
    <ExperienceContainer>
      <Timeline>
        <DateRange>
          {splittedStartDate[0]}
          {splittedStartDate[1]} - {endDate}
        </DateRange>
      </Timeline>
      <DetailsWrapper>
        <Description>{description}</Description>
      </DetailsWrapper>
    </ExperienceContainer>
  )
}

export default Experience
