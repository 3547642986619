import React from "react"
import Container from "./styles/Container"
import Title from "./styles/Title"
import UnderLine from "./styles/UnderLine"
import styled from "styled-components"
import Experience from "./experience"

const Line = styled(UnderLine)`
  margin: 0 2.5rem;
  margin-bottom: 4.5rem;
  width: 310px;
  @media (max-width: 768px) {
    margin: 0 auto;
    margin-bottom: 3rem;
  }
`
const WorkExperience = () => {
  return (
    <Container>
      <Title>Work Experience</Title>
      <Line />
      <Experience
        startDate="Jan. 2021"
        endDate="Present"
        description="Vascar Solutions | Front-end developer (React, React Native, GraphQL, Redux, TypeScript), focusing on building and maintaining user interfaces using advanced technologies. I developed dynamic web applications with React and mobile applications with React Native. I utilized GraphQL to optimize data queries and Redux for state management in applications. Additionally, I leveraged TypeScript to enhance code robustness and maintainability, ensuring a seamless and high-quality user experience."
      />
      <Experience
        startDate="Feb. 2019"
        endDate="Jan. 2021"
        description="Clichead | Front-end developer with a focus on developing and managing dynamic websites using WordPress and WooCommerce. Additionally, I handled email management tasks using AWS services, ensuring efficient and reliable communication systems. My responsibilities included collaborating with design teams to create seamless user experiences, maintaining the technical aspects of web infrastructure, and optimizing website performance for better user engagement."
      />
      <Experience
        startDate="Oct. 2018"
        endDate="Feb. 2019"
        description="BlackPoint Software | Full-stack developer. Full-stack development (PHP, JavaScript, HTML/CSS) focusing on both front-end and back-end development. My responsibilities included creating and maintaining web applications using PHP for server-side logic and JavaScript for client-side interactivity. I also utilized HTML and CSS to design and style web pages, ensuring responsive and user-friendly interfaces."
      />
    </Container>
  )
}

export default WorkExperience
